<template>
  <section>
    <b-modal id="modal-admin-college" hide-footer>
      <template #modal-header>
        <h4>
          {{ $t("admin-college.new") }}
        </h4>
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="new-user-info">
            <b-row class="justify-content-md-center">
              <b-form-group class="col-md-12" label-for="user">
                <ValidationProvider
                  :name="$t('user-college.user')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="data.user_id"
                    :placeholder="$t('app.min-search')"
                    label="name"
                    :options="wrappedUserCollege"
                    @search="(query) => getDebouncedUsers(query)"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                    <template #option="{ first_lastname, name, email }">
                      <p style="margin: 0">
                        {{ `${name} ${first_lastname ? first_lastname : ""}` }}
                      </p>
                      <small>{{ email }}</small>
                    </template>
                    <template #selected-option="{ first_lastname, name }">
                      <p style="margin: 0">
                        {{ `${name} ${first_lastname ? first_lastname : ""}` }}
                      </p>
                    </template>

                    <template #no-options>
                      {{ $t("app.not-found") }}...
                    </template>
                  </v-select>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <hr />
            <b-button
              :disabled="userCollegeLoading"
              variant="success"
              class="float-right"
              type="submit"
            >
              <span v-if="userCollegeLoading">
                <b-spinner small type="grow"></b-spinner>
                {{ $t("app.loading") }}...
              </span>
              <span v-else>
                {{ $t("app.save") }}
              </span>
            </b-button>
            <b-button
              @click="$bvModal.hide('modal-admin-college')"
              class="float-right mr-1"
              variant="outline-danger"
              >{{ $t("app.cancel") }}</b-button
            >
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import {
  ROLE_ADMIN_ID,
  ROLE_HOLDER_ID,
  ROLE_ADMINISTRATIVE_ID,
} from "@/constants/roles";

export default {
  name: "modalForm",
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        user_id: null,
      },
      users: {
        loading: false,
        debounce: null,
        debounceTime: 300,
      },
    };
  },
  methods: {
    ...mapActions({
      storeUserCollege: "storeUserCollege",
      fetchUserCollege: "fetchUserCollege",
    }),
    updateCurrentUser(user) {
      this.data.user_id = user;
    },
    async getDebouncedUsers(search) {
      this.users.loading = true;
      clearTimeout(this.users.debounce);
      this.users.debounce = await setTimeout(async () => {
        if (search.length >= 2) {
          this.users.loading = true;
          try {
            let paramsSearch = {
              search,
              roles: [ROLE_ADMINISTRATIVE_ID, ROLE_HOLDER_ID],
            };
            await this.fetchUserCollege(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.users.loading = false;
          }
        }
      }, this.users.debounceTime);
    },
    async show() {
      this.reserForm();
      this.$bvModal.show("modal-admin-college");
    },
    async onSubmit() {
      let payload = {
        id: this.data.user_id.id,
        user_id: this.data.user_id.id,
        roles: [
          ...this.data.user_id.roles.map((item) => item.id),
          ROLE_ADMIN_ID,
        ],
      };
      const resp = await this.storeUserCollege(payload);
      this.$bvModal.hide("modal-admin-college");
      this.$emit("refresh");
      if (resp.status === 201 || resp.status === 200) {
        core.showSnackbar(
          "success",
          this.$t("admin-college.created-successfully")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        user_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      userCollegeLoading: "userCollegeLoading",
      getUserCollege: "getUserCollege",
      collageAuthUser: "collageAuthUser",
    }),
    wrappedUserCollege() {
      return this.getUserCollege.map((item) => {
        return {
          ...item,
          user: null,
          ...item.user,
        };
      });
    },
  },
};
</script>
