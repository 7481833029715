<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("sidebar.admin-college") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$refs.modalAdminCollege.show()"
              >{{ $t("app.new") }}</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
                <b-form-input
                  style="height: 35px"
                  id="filter-input"
                  :disabled="
                    !Array.isArray(getAdminCollege.data) ||
                    (getAdminCollege.data.length === 0 &&
                      userCollegeLoading === false)
                  "
                  v-model="filter.search"
                  type="search"
                  :placeholder="$t('app.search')"
                ></b-form-input>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="getAdminCollege.data"
                  :fields="columns"
                  :per-page="filter.per_page"
                  :filter="filter"
                  :busy="
                    !Array.isArray(getAdminCollege.data) ||
                    getAdminCollege.data.length === 0
                      ? true
                      : false || userCollegeLoading == true
                  "
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="userCollegeLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong>{{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(roles)="data">
                    <p v-for="item in data.item.roles" :key="item.id">
                      {{ item.name }}
                    </p>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button
                      @click="deleteAdminCollege(data.item)"
                      variant=" iq-bg-danger rounded"
                      size="sm"
                    >
                      <i class="ri-delete-bin-line ml-1"></i>
                    </b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 float-right"
                  v-model="filter.page"
                  :total-rows="getAdminCollege.total"
                  :per-page="getAdminCollege.per_page"
                  first-number
                  pills
                  size="sm"
                  @change="getData"
                ></b-pagination>
                <p class="mt-3">
                  {{ $t("app.registers") }} {{ getAdminCollege.to }}
                  {{ $t("app.of") }} {{ getAdminCollege.total }}. Total
                  {{ getAdminCollege.total }}
                </p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalAdminCollege" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { ROLE_ADMIN_ID } from "@/constants/roles";
export default {
  name: "AdminCollege",
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.debouncedGetAdminColleges = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAdminColleges();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchUserCollege: "fetchUserCollege",
      updateRolesAdminCollege: "storeUserCollege",
    }),
    getData(page = 1) {
      this.filter.page = page;
      this.fetchUserCollege({
        ...this.filter,
        roles: [ROLE_ADMIN_ID],
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteAdminCollege(admin) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            let payload = {
              id: admin.user.id,
              user_id: admin.user.id,
              roles: [
                ...admin.roles
                  .filter((item) => item.id !== ROLE_ADMIN_ID)
                  .map((item) => item.id),
              ],
            };
            const resp = await this.updateRolesAdminCollege(payload);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar(
                "success",
                this.$t("admin-college.deleted-successfully")
              );
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
    };
  },
  computed: {
    ...mapGetters({
      getAdminCollege: "getAdminCollege",
      userCollegeLoading: "userCollegeLoading",
      getLoadingRoles: "getLoadingRoles",
      collageAuthUser: "collageAuthUser",
    }),
    columns() {
      return [
        {
          label: this.$t("user-college.rut"),
          key: "user.rut",
          class: "text-center",
        },
        {
          label: this.$t("user-college.user"),
          key: "user",
          class: "text-center",
          formatter: (user) => `${user.name} ${user.first_lastname ?? ""}`,
        },
        {
          label: this.$t("user-college.phone"),
          key: "user.phone",
          class: "text-center",
          formatter: (phone) => phone ?? "Sin teléfono",
        },
        {
          label: this.$t("user-college.roles"),
          key: "roles",
          class: "text-center",
        },
        {
          label: this.$t("user-college.email"),
          key: "user.email",
          class: "text-center",
          formatter: (email) => email ?? "Sin correo electrónico",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
